import { createStyles } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = () =>
  createStyles({
    root: {
      color: colors.black.darker,
    },
  });
